import property from 'lodash/property';
import strings from 'assets/strings/index';
import { APP_MODE } from '../applicationConstants/envVariables';
import { ALLOWED_MODE } from '../applicationConstants';

export default function getText(textPath: string) {
  const text = (property(textPath)(strings.general) as string);

  if (!text) {
    return APP_MODE === ALLOWED_MODE.local || APP_MODE === ALLOWED_MODE.dev ? textPath : '';
  }

  return text;
}
