import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';

export const getNetworksListApiRequest = () => apiRequest(
  'network',
  {
    method: 'GET',
  },
);

export const setNetworkApiRequest = (networkId: string) => apiRequest(
  `user/me/network${toParams({ network: networkId })}`,
  {
    method: 'POST',
  },
);

export type CreateNetworkBody = {
  name: string;
  flags: {
    enableSelectLenderByDefault: boolean;
    guestOnlyFlow: boolean;
    managersOnlyRefunds: boolean;
    preferLowestMonthlyOffer: boolean;
  };
};

export const createNetworkApiRequest = (body: CreateNetworkBody) => apiRequest(
  'network',
  {
    method: 'POST',
    body: {
      ...body,
      configuration: {
        lenderTiers: [],
        enablePublicLink: false,
      },
    },
  },
);
export const updateNetworkApiRequest = (id: string, body: CreateNetworkBody) => apiRequest(
  `network/${id}`,
  {
    method: 'POST',
    body,
  },
);
