import { AnyAction } from 'redux';
import { setApiMode } from 'utils/apiModeUtils';
import get from 'lodash/get';
import log from 'loglevel';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import { getInviteDetailsAction } from 'store/invite';
import {
  authActions,
  userActions,
  UNAUTHORIZED_USER,
  loadInviteLocationActions,
  authByInviteActions,
  checkForExistingUserActions,
  setGuestAuthorizationMethod,
} from './actionTypes';
import { IAuthReducer } from './types';
import { TawkProxyInstance } from '../../utils/TawkProxy';
import { setNetworkActions } from '../networks';
import {
  SUPPORT_CLARITY_KEY,
  SUPPORT_LOGROCKET_KEY,
  SUPPORT_WIDGET_PROVIDER,
} from '../../applicationConstants/envVariables';
import { applicationFlowMode } from '../../utils/applicationModeUtils';
import { updateNetworkActions } from '../networkManagement';

const initialState: IAuthReducer = {
  status: 'initial',
  error: null,
  location: undefined,
  userInfo: undefined,
  unauthorizedUserError: undefined,
  userInformationSet: false,
  isGuestAuthorized: false,
  existingAccount: undefined,
  guestAuthorizationMethod: undefined,
};

export function authReducer(state = initialState, action: AnyAction): IAuthReducer {
  const { type, payload } = action;
  switch (type) {
    case setGuestAuthorizationMethod.TYPE: {
      return {
        ...state,
        guestAuthorizationMethod: payload.data,
      };
    }
    case checkForExistingUserActions.SUCCESS:
      return {
        ...state,
        existingAccount: payload.data,
      };
    case authActions.REQUEST:
      return {
        ...state,
        isAuthorized: false,
        error: null,
      };
    case authActions.SUCCESS: {
      const {
        data: {
          network, location, user, region,
        },
      } = payload;
      setApiMode(payload.data.network.mode);
      if (!state.userInformationSet) {
        try {
          const id = get(user, 'id', 'undefined');
          const intercomHash = get(user, 'intercomHash');
          const userName = get(user, 'fullName', 'undefined');
          const email = get(user, 'email', 'undefined');
          const locationName = get(location, 'name', 'undefined');
          const locationId = get(payload.data, 'location.id', 'undefined');
          const networkName = get(network, 'name', 'undefined');
          const networkId = get(network, 'id', 'undefined');
          const regionName = get(region, 'name', 'undefined');
          const regionId = get(region, 'id', 'undefined');
          const role = get(user, 'role', 'undefined');
          Sentry.setUser({
            email,
            username: userName,
            locationName,
            locationId,
            networkName,
            networkId,
            regionName,
            regionId,
            id,
            role,
          });

          if (SUPPORT_LOGROCKET_KEY) {
            LogRocket.identify(id, {
              name: userName || '',
              email: email || '',

              // Add your own custom user variables here, ie:
              subscriptionType: 'pro',
              networkName,
              networkId,
              locationId,
              locationName,
              regionName,
              regionId,
            });
          }

          if (SUPPORT_CLARITY_KEY) {
            // @ts-ignore
            const { clarity } = window;
            if (id) {
              clarity('identify', 'custom-id', id || '');
              clarity('set', 'userId', id || '');
              clarity('set', 'userName', userName || '');
              clarity('set', 'email', email || '');
            }
            if (networkId) {
              clarity('set', 'networkName', networkName || '');
              clarity('set', 'networkId', networkId || '');
            }
            if (locationId) {
              clarity('set', 'locationId', locationId || '');
              clarity('set', 'locationName', locationName || '');
            }
            if (regionId) {
              clarity('set', 'regionName', regionName || '');
              clarity('set', 'regionId', regionId || '');
            }

            clarity('set', 'role', role.toString() || '');
          }

          if (SUPPORT_WIDGET_PROVIDER === 'tawk') {
            TawkProxyInstance.setAttributes({
              name: userName,
              email,
              locationName,
              networkName,
              regionName,
            }, (error: any) => {
              if (error) {
                log.error(error);
                Sentry.captureException(error);
              }
            });
          }

          if (SUPPORT_WIDGET_PROVIDER === 'intercom') {
            const companies = [];
            if (network) {
              companies.push({
                company_id: networkId,
                name: networkName,
                plan: 'Network',
              });
            }

            if (applicationFlowMode !== 'public') {
              // @ts-ignore
              window.Intercom('boot', {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                name: userName, // Full name
                email, // Email address
                user_id: id,
                user_hash: intercomHash,
                companies,
                Location: locationName,
                Region: regionName,
              });
            }
          }
        } catch (err: any) {
          log.error(err);
          Sentry.captureException(err);
        }
      }

      return {
        ...state,
        userInformationSet: true,
        status: 'loaded',
        location,
        userInfo: user,
        network,
      };
    }
    case userActions.SUCCESS:
      return {
        ...state,
        status: 'loaded',
        userInfo: payload.data,
      };
    case updateNetworkActions.SUCCESS:
      return {
        ...state,
        network: payload.data,
      };
    case authActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload.data,
      };
    case UNAUTHORIZED_USER:
      return {
        ...state,
        unauthorizedUserError: payload,
      };
    case loadInviteLocationActions.SUCCESS: {
      Sentry.setUser({
        username: 'guest user',
        location: payload.location.id,
        network: payload.network.id,
        mode: payload.location.mode,
        invite: payload.invite,
        role: 50,
      });

      return {
        ...state,
        status: 'loaded',
        location: payload.location,
        network: payload.network,
        userInfo: {
          role: 50,
          location: payload.location.id,
          network: payload.network.id,
        },
      };
    }
    case authByInviteActions.SUCCESS:
      if (state.isGuestAuthorized) {
        return state;
      }
      return {
        ...state,
        isGuestAuthorized: true,
      };
    case getInviteDetailsAction.SUCCESS:
      // if we're in guest mode and invite doesn't have submission or splitit, we're authorised
      // to view the page and after creations of submission or splitit, we're still authorised
      if (applicationFlowMode !== 'guest') return state;

      return {
        ...state,
        isGuestAuthorized: !payload.data?.shouldAuthorize,
      };
    case setNetworkActions.REQUEST: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          network: payload.id,
        },
        network: payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
